import React, { FC } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { graphql } from 'gatsby';

import './LdsPage.scss';

import Layout from 'components/Layout';
import DangerouslySetInnerHtml from 'components/common/DangerouslySetInnerHtml';

import { LdsPageProps } from './models';

const LdsPage: FC<LdsPageProps> = ({
  pageContext,
  data: {
    allLds: {
      nodes: [{ seoMetaTitle, seoMetaDescription, seoMetaKeywords, title }],
    },
  },
}) => {
  const {
    body,
    breadcrumb: { crumbs },
  } = pageContext;

  const crumbsSettings = {
    crumbs,
  };

  return (
    <Layout
      crumbsSettings={crumbsSettings}
      seo={{
        seoMetaTitle,
        seoMetaDescription,
        seoMetaKeywords,
      }}
    >
      <section className="lds-page">
        <Container fluid>
          <Row>
            <Col>
              <h2 className="lds-page__header">{title}</h2>
              <DangerouslySetInnerHtml html={body} />
            </Col>
          </Row>
        </Container>
      </section>
    </Layout>
  );
};

export const query = graphql`
  query($slug: String) {
    allLds(filter: { fields: { slug: { eq: $slug } } }) {
      nodes {
        title
        seoMetaDescription
        seoMetaTitle
        seoMetaKeywords
      }
    }
  }
`;

export default LdsPage;
